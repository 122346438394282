<template>
  <div
    class="pa-4"
    :style="{ 'background-color': color1 }"
    style="
      height: 130px !important;
      border-radius: 14px;
      color: white;
      width: 100%;
    "
  >
    <div class="d-flex justify-content-between">
      <h1>{{ title }}</h1>
      <div
        :style="{ 'background-color': color2 }"
        style="
          height: 40px;
          width: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
        "
      >
        <v-icon :color="color1">{{ icon }}</v-icon>
      </div>
    </div>
    <div style="font-size: 42px">{{ value }}</div>
  </div>
</template>

<script>
export default {
  name: "ImportHeaderCard",
  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    value: {
      required: true,
      type: Number,
    },
    theme: {
      required: true,
      type: String,
      validator: (value) => {
        return ["success", "info", "error"].includes(value);
      },
    },
  },
  computed: {
    color1() {
      switch (this.theme) {
        case "success":
          return "rgba(39, 167, 67, 1)";
        case "error":
          return "rgba(245, 77, 95, 1)";
        case "info":
          return "rgba(0, 147, 245, 1)";
        default:
          return "rgba(244, 244, 244, 0.5)";
      }
    },
    color2() {
      switch (this.theme) {
        case "success":
          return "rgba(13, 111, 35, 1)";
        case "error":
          return "rgba(172, 49, 62, 0.8)";
        case "info":
          return "rgba(0, 73, 143, 0.8)";
        default:
          return "rgba(255, 255, 255, 1)";
      }
    },
  },
};
</script>

<style></style>
