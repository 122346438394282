<template>
  <v-dialog v-model="dialog" persistent max-width="1000" v-if="userSocketData">
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder" v-if="importTypeRelatedData">
          {{ importTypeRelatedData.text }}
        </h4>
        <v-spacer></v-spacer>
        <v-btn icon @click="toggleModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card>
        <v-stepper v-model="step" class="ma-0 pa-0">
          <v-stepper-header class="ma-0 pa-0">
            <v-stepper-step
              :complete="step > 1"
              step="1"
              edit-icon="mdi-upload"
              complete-icon="mdi-upload"
              error-icon="mdi-upload"
            >
              Form
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 2"
              step="2"
              edit-icon="mdi-sync"
              complete-icon="mdi-sync"
              error-icon="mdi-sync"
            >
              Progress
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card
                class="mb-12 h-100 mx-0 px-0"
                height="600px"
                elevation="0"
              >
                <component
                  v-bind:is="importTypeForm"
                  :importTypeData="importTypeRelatedData"
                  :submit="submitImportFormData"
                  ref="form"
                ></component>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2" class="ma-0 pa-0">
              <v-card class="h-100" height="600px" elevation="0">
                <div
                  class="pt-8 pb-8 px-2"
                  style="
                    width: 100%;
                    box-shadow: rgba(0, 0, 0, 0.5) 0 1px 3px inset;
                    border-radius: 0px;
                  "
                >
                  <ImportHeader
                    :error="importProcessTotalError"
                    :success="importProcessCurrentTask"
                    :total="importProcessTotalTask"
                  />
                </div>
                <v-card>
                  <div class="h3 font-weight-bold pb-2 pt-4 pl-6">
                    Event Log
                  </div>
                  <v-divider class="ma-0 pa-0"></v-divider>
                  <div>
                    <EventLogTable :items="importProcessLog" />
                  </div>
                </v-card>
              </v-card>
              <div class="d-flex justify-content-end align-center py-4 px-4">
                <v-btn
                  text
                  class="px-5 py-3 mx-2 ls1 text-danger font-weight-bold text-capitalize"
                  :href="getFailedDownloadsFile"
                  v-if="getFailedDownloadsFile"
                >
                  Download Failed Report
                </v-btn>

                <button
                  class="btn btn-info px-8 py-3 mx-2 ls1"
                  @click="toggleModal"
                  :disabled="!getIsImportProcessEnded"
                >
                  Finish Upload
                </button>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";

import Swal from "sweetalert2";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import getPusherInstance from "@/core/services/pusher.service";

import ImportHeader from "./ImportHeader.vue";
import EventLogTable from "./EventLogTable.vue";

export default {
  name: "ImportData",
  components: { ImportHeader, EventLogTable },
  props: {
    updateTableContent: {
      required: true,
      type: Function,
    },
    importTypeRelatedData: {
      required: true,
    },
    importTypeForm: {
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    step: 1,
    formData: null,
    importProcessLog: [],
    isImportProcessEnded: false,
    importProcessTotalTask: 0,
    importProcessCurrentTask: 0,
    importProcessTotalError: 0,
    importProcessFailedFilePath: null,
    failedDownloadsFile: null,
    channel: null,
    pusher: null,
    timerForError: null,
  }),
  beforeDestroy() {
    if (this.pusher) {
      if (this.channel) {
        this.channel.unbind_all();
      }
      this.pusher.unsubscribe(this.userSocketData.channel);
    }
  },
  computed: {
    demoData() {
      return [
        {
          status: "info",
          reference: "order0001",
          description: "some description for testing - data order0001",
        },
        {
          status: "error",
          reference: "order0001",
          description: "some description for testing - data order0001",
        },
        {
          status: "info",
          reference: "order0001",
          description: "some description for testing - data order0001",
        },
        {
          status: "error",
          reference: "order0001",
          description: "some description for testing - data order0001",
        },
        {
          status: "info",
          reference: "order0001",
          description: "some description for testing - data order0001",
        },
      ];
    },
    userSocketData: function () {
      /**
       * @type {{key:string,wsHost:string,wsPort:number,wssPort:number,channel:string,event:string}}
       * @example {"key":"BKFNMJNMGF","wsHost":"apitest.iqfulfillment.com","wsPort":6001,"wssPort":6001,"channel":"user.127","event":".notification.created"}
       */
      const socketData = this.$store.getters.getSocketData;
      return socketData;
    },

    getProcessLogMessage() {
      if (this.importProcessLog.length === 0) {
        return "Loading ...";
      }
      let content = "<div>";
      this.importProcessLog.forEach((data) => {
        content = content + `<div class="${data.class}">${data.message}</div>`;
      });
      content += `</div>`;
      return content;
    },
    getIsImportProcessEnded() {
      return this.isImportProcessEnded;
    },
    getFailedDownloadsFile() {
      return this.failedDownloadsFile;
    },
  },
  methods: {
    stopProcess() {},
    addMessageToLog(data) {
      switch (data.type) {
        case "start":
          this.importProcessLog.push({
            status: "info",
            reference: "",
            description: data?.message,
          });
          break;
        case "error":
          this.importProcessLog.push({
            status: "error",
            reference: data?.reference,
            description: data?.message,
          });
          break;
        case "end":
          this.importProcessLog.push({
            status: "info",
            reference: "",
            description: data?.message,
          });
          break;
        case "info":
          this.importProcessLog.push({
            status: "info",
            reference: data?.reference,
            description: data?.message,
          });
          break;
        default:
          return;
      }
      // this.importProcessLog.push({
      //   message: message,
      // });
    },
    handleConfirmationMessage(notification) {
      Swal.fire({
        title: "Upload warning!",
        html: notification.message,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, continue!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.post("/fulfillment/orders/confirm", {
            token: notification.token,
          })
            .then(() => {
              Swal.fire("Confirmed!", "Upload is resumed", "info");
            })
            .catch(() => {
              Swal.fire(
                "Error happened during process!",
                "Something went wrong during import process!",
                "error"
              );
            });
        } else {
          this.stopProcess();
        }
      });
    },
    initSocketConnection: function (eventName) {
      this.step = 2;
      this.$store.commit(SET_PAGE_LOADING, false);
      this.channel.bind(`${eventName}`, (notification) => {
        if (this.timerForError) {
          clearTimeout(this.timerForError);
          this.timerForError = null;
        }
        switch (notification.type) {
          case "start":
            this.importProcessTotalTask = notification.total;
            this.addMessageToLog(notification);
            // this.importProcessCurrentTask = notification.total_success;
            // this.importProcessTotalError = notification.total_error;
            break;
          case "end":
            this.addMessageToLog(notification);
            this.failedDownloadsFile = notification.failed_file_path;
            this.isImportProcessEnded = true;
            this.channel.unbind_all();
            this.pusher.unsubscribe(this.userSocketData.channel);
            this.updateTableContent();
            break;
          case "info":
            this.importProcessTotalTask = notification.total;
            this.addMessageToLog(notification);
            this.importProcessCurrentTask = notification.total_success;
            this.importProcessTotalError = notification.total_error;
            break;
          case "success":
            this.importProcessTotalTask = notification.total;
            this.importProcessCurrentTask = notification.total_success;
            this.importProcessTotalError = notification.total_error;
            break;
          case "error":
            this.addMessageToLog(notification);
            this.importProcessTotalTask = notification.total;
            this.importProcessCurrentTask = notification.total_success;
            this.importProcessTotalError = notification.total_error;
            break;
          case "popUpConfirmationMessage":
            this.handleConfirmationMessage(notification);
            this.addMessageToLog(notification);
            break;
        }
      });
    },
    submitImportFormData(data) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.formData = data;
      this.pusher = getPusherInstance();
      this.channel = this.pusher.subscribe(this.userSocketData.channel);
      ApiService.post(`/account/uploads/upload`, this.formData).then((data) => {
        this.initSocketConnection(data.data.event);
        this.timerForError = setTimeout(() => {
          Swal.fire("Time out", "Socket is not responding!", "error");
          this.toggleModal();
        }, 60 * 1000);
      });
    },
    resetComponent() {
      this.dialog = false;
      this.step = 1;
      this.formData = null;
      this.isImportProcessEnded = false;
      this.importProcessTotalTask = 0;
      this.importProcessCurrentTask = 0;
      this.importProcessTotalError = 0;
      this.importProcessFailedFilePath = null;
      this.failedDownloadsFile = null;
      this.importProcessLog = [];
    },
    toggleModal() {
      if (!this.dialog) {
        this.resetComponent();
      } else {
        if (this.pusher) {
          if (this.channel) {
            this.channel.unbind_all();
          }
          this.pusher.unsubscribe(this.userSocketData.channel);
        }
      }
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style scoped>
.modal-content {
  height: 76vh;
  max-height: 1000px;
}

.messagebox {
  max-height: 600px;
  overflow-y: scroll;
}
</style>
