<template>
  <div>
    <v-data-table
      height="200"
      :headers="headers"
      :items="items"
      hide-default-footer
      dense
      fixed-header
      :items-per-page="200"
    >
      <template v-slot:[`item.status`]="{ item }">
        <div class="row justify-content-start align-center pl-6">
          <template v-if="item.status === 'error'">
            <v-icon color="red">mdi-exclamation</v-icon>
            <div>{{ item.status }}</div>
          </template>
          <template v-if="item.status === 'info'">
            <v-icon color="green">mdi-check</v-icon>
            <div>{{ item.status }}</div>
          </template>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "EventLogTable",
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  methods: {},
  computed: {
    headers() {
      return [
        {
          text: "Status",
          value: "status",
          sortable: false,
          align: "left",
          class: "h5 pl-6",
          cellClass: "h6 font-weight-normal",
        },
        {
          text: "Reference",
          value: "reference",
          sortable: false,
          align: "left",
          class: "h5",
          cellClass: "h6 font-weight-normal",
        },
        {
          text: "Description",
          value: "description",
          sortable: false,
          align: "left",
          class: "h5",
          cellClass: "h6 font-weight-normal",
        },
      ];
    },
  },
};
</script>
<style scoped>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.04);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
